import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  TextareaAutosize,
  Button,
  Link,
  InputAdornment,
} from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { Field } from 'redux-form'
import { Alert } from '@material-ui/lab'

import RenderRadio from 'components/shared/Form/RenderRadio'
import RenderTextField from 'components/shared/Form/RenderTextField'
import FormDialogContainer from 'components/shared/FormDialog/FormDialogContainer'
import Label from 'components/shared/Label/Label'
import {
  ENABLE_DIAG_EDIT,
  FIELD_DIAG_AMOUNT,
  FIELD_DIAG_COMMENT,
  FIELD_DIAG_NEW_JOB_REQUIRED,
  JOB_IN_PROGRESS_STATUS,
} from 'constants/Jobs'
import { hasPerm, PERM_JOB_EDIT_DIAGNOSTIC } from 'constants/permissions'

import styles from './JobDiagnosticStyles'

const JobDiagnostic = ({
  diagInfos: {
    newJobRequired,
    additionalFeesAmount,
    comment,
    createdAt,
    jobId,
  },
  classes,
  translate,
  token,
  submitFormDispatch,
  formErrors,
  formValues,
  form,
  currentJob,
  invalid,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const userHasPermission = hasPerm(token, PERM_JOB_EDIT_DIAGNOSTIC)
  const [formHasErrors, setFormHasErrors] = useState(false)

  useEffect(() => {
    setFormHasErrors(false)
    Object.keys(formErrors).map(error => {
      if (formErrors[error] !== undefined) {
        setFormHasErrors(true)
      }

      return formHasErrors
    })
  }, [formErrors, formHasErrors])

  const handleEditFormClose = () => {
    setDialogOpened(!confirmDialogOpened)
  }
  const handleResponse = () => {
    submitFormDispatch()
    handleEditFormClose()
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {!jobId?.includes(currentJob?.jobId) ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography className={classes.typography}>
                    {translate('resources.jobs.diagnostic.create.description')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    name="createDiagnostic"
                    className={classes.buttonBigger}
                    variant="contained"
                    component={Link}
                    onClick={() => setDialogOpened(true)}
                    disabled={!userHasPermission}
                  >
                    {translate('resources.jobs.diagnostic.create.button')}
                  </Button>
                  {!userHasPermission && (
                    <Alert severity="warning" className={classes.warningAlert}>
                      {translate('resources.permission.manager.required')}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography className={classes.typography}>
                  {translate('resources.jobs.diagnostic.date_realization')}{' '}
                  <span className={classes.bold}>
                    {/* Format the date to display only the date without the time (e.g. 24-09-2024) */}
                    {new Date(createdAt).toLocaleDateString('fr-FR')}
                  </span>
                </Typography>
                <Typography className={classes.typography}>
                  {translate('resources.jobs.diagnostic.result')}{' '}
                  <span className={classes.bold}>
                    {newJobRequired
                      ? translate('resources.jobs.diagnostic.newJobRequired')
                      : translate('resources.jobs.diagnostic.noNewJobRequired')}
                  </span>
                </Typography>
                <Typography className={classes.typography}>
                  {translate('resources.jobs.diagnostic.additional_fees')}{' '}
                  <span className={classes.bold}>
                    {additionalFeesAmount > 0
                      ? `${additionalFeesAmount} €HT`
                      : translate('resources.jobs.diagnostic.noAdditionalFees')}
                  </span>
                </Typography>
                <TextareaAutosize
                  className={classes.textArea}
                  readOnly
                  value={comment}
                />
                {currentJob?.status === JOB_IN_PROGRESS_STATUS &&
                  ENABLE_DIAG_EDIT.includes(currentJob?.substatus) && (
                    <>
                      <Button
                        name="generateDisputeDocuments"
                        className={classes.buttonSecondary}
                        variant="contained"
                        component={Link}
                        onClick={() => setDialogOpened(true)}
                        disabled={!userHasPermission}
                      >
                        <EditOutlined />
                        {translate('job.details.client.form.pop_in.button')}
                      </Button>
                      {!userHasPermission && (
                        <Alert
                          severity="warning"
                          className={classes.warningAlert}
                        >
                          {translate('resources.permission.manager.required')}
                        </Alert>
                      )}
                    </>
                  )}
              </>
            )}
            {confirmDialogOpened && (
              <FormDialogContainer
                title="diagnostic.edit.form.title"
                onSubmit={handleResponse}
                handleClose={handleEditFormClose}
                form={form}
                translate={translate}
                invalid={invalid}
              >
                <Grid className={classes.marginBottom}>
                  <Grid
                    className={classes.formGridPadding}
                    container
                    direction="column"
                  >
                    <Label
                      label={translate(
                        'resources.jobs.diagnostic.form.newJobRequired',
                      )}
                    />
                    <Field
                      name={FIELD_DIAG_NEW_JOB_REQUIRED}
                      component={RenderRadio}
                      choices={[
                        {
                          name: translate(
                            'resources.jobs.diagnostic.form.radio.newJobRequired',
                          ),
                          value: 'true',
                          key: 'newJobRequired',
                        },
                        {
                          name: translate(
                            'resources.jobs.diagnostic.form.radio.newJobNotRequired',
                          ),
                          value: 'false',
                          key: 'newJobNotRequired',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid
                    className={classes.formGridPadding}
                    container
                    direction="column"
                  >
                    <Label
                      label={translate(
                        'resources.jobs.diagnostic.form.comment',
                      )}
                    />
                    <Field
                      name={FIELD_DIAG_COMMENT}
                      component={RenderTextField}
                      className={classes.textArea}
                      multiline
                      variant="outlined"
                      maxRows={3}
                      rows={3}
                      type="textarea"
                    />
                  </Grid>
                  {formValues?.[FIELD_DIAG_NEW_JOB_REQUIRED] === 'false' && (
                    <Grid
                      className={classes.formGridPadding}
                      container
                      direction="column"
                    >
                      <Label
                        label={translate(
                          'resources.jobs.diagnostic.form.additionalFeesAmount',
                        )}
                      />
                      <Field
                        id={FIELD_DIAG_AMOUNT}
                        name={FIELD_DIAG_AMOUNT}
                        component={RenderTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        }}
                        fullWidth
                        value=""
                        type="number"
                      />
                    </Grid>
                  )}
                </Grid>
              </FormDialogContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

JobDiagnostic.propTypes = {
  classes: PropTypes.shape({
    typography: PropTypes.string.isRequired,
    textArea: PropTypes.string.isRequired,
    bold: PropTypes.string.isRequired,
    buttonSecondary: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    simpleInputText: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    warningAlert: PropTypes.string.isRequired,
    inputTextarea: PropTypes.string.isRequired,
    formGridPadding: PropTypes.string.isRequired,
  }).isRequired,
  diagInfos: PropTypes.shape({
    newJobRequired: PropTypes.bool.isRequired,
    additionalFeesAmount: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    jobId: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  token: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  }).isRequired,
  submitFormDispatch: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  formValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.string.isRequired,
  currentJob: PropTypes.shape({
    status: PropTypes.string.isRequired,
    substatus: PropTypes.string,
    jobId: PropTypes.string.isRequired,
  }).isRequired,
  invalid: PropTypes.bool.isRequired,
}

export default compose(i18n, withStyles(styles))(JobDiagnostic)
